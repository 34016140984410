<template>
  <div class="live-message" :class="{ 'full-message': isFullScreen }">
    <div class="msg-scroll" ref="msgScroll">
      <h4 class="tips">
        请和谐发言
      </h4>
      <ul class="msg-list" id="msg-list">
        <li v-for="(msg, index) in msgs" :key="index">

          <img :src="msg.avatar" alt="" /><span
            @click.stop="showFloatUser(msg)"
            class="name"
            >{{ msg.nickname }}: </span
          ><span v-html="msg.message"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import levelIcon from '../../common/js/level-icon'
import Scroll from '../../components/scroll/scroll'
import { blurInput } from '../../common/js/mixins'
import { getItem } from '../../common/js/storage'
import { get } from '../../common/js/http'

export default {
  name: 'live-message',
  mixins: [blurInput],
  components: {
    Scroll
  },
  props: {
    roomId: {
      type: String,
      default: ''
    },
    isAnchor: {
      type: Boolean,
      default: false
    },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      msgs: [],
      IMToken: null,
      message: '',
      $client: null,
      medals: [
        require('./youxia@2x.png'),
        require('./qishi@2x.png'),
        require('./gongjue@2x.png'),
        require('./king@2x.png')
      ],
      levelIcon,
      channelName: null,
      joinSuccess: false
    }
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        if (newVal && newVal.user_id) {
          this.initIm()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState(['userInfo', 'appConfig', 'tim'])
  },
  mounted() {

  },
  async beforeDestroy() {
    await this.$client.leaveChannel()
    const { id } = this.$route.params
    this.queryChannelUsers(id)
  },
  methods: {
    ...mapMutations(['changeIm', 'queryChannelUsers']),

    async initIm() {
      await this.getRTMConfig()
      this.$client.init('8f9a93194446405089ec94f5418084ea')
      const { user_id } = this.$route.query
      this.$client.login(user_id == String(this.userInfo.user_id) ? String(this.userInfo.user_id * 1000) : String(this.userInfo.user_id), this.IMToken).then(() => {
        this.$emit('isLogined')
      }).catch((err) => {
        console.log(err)
      })
    },

    async getRTMConfig() {
      const { user_id } = this.$route.query
      const params = {}
      if (user_id == this.userInfo.user_id) {
        params.is_audience = 1
      }
      const { data: { data } } = await get('/user/rtmToken', params)
      this.IMToken = data.rtm_token
      console.log(this.IMToken)
    },

    to_footer() {
      const div = document.getElementById('msg-list')
      div.scrollTop = div.scrollHeight
    },

    async joinChannel (name = '222') {
      this.channelName = String(name)
      console.log('this.channelName================================>' + this.channelName)
      this.$client.joinChannel(this.channelName).then(() => {
        const msg = '进入直播间'
        this.$client.sendChannelMessage(msg, this.channelName).then(() => {
          this.msgs.push({
            message: msg,
            nickname: this.userInfo.nickname,
            avatar: this.userInfo.avatar
          })
          this.$nextTick(() => {
            this.to_footer()
          })
          const { id } = this.$route.params
          this.queryChannelUsers(id)
        }).catch((err) => {
          console.error(err)
        })

        this.$client.on('ConnectionStateChanged', (newState, reason) => {
          if (newState == 'DISCONNECTED') {
            this.$client.login(String(this.userInfo.user_id), this.IMToken).then(() => {
              this.$emit('isLogined')
            }).catch((err) => {
              console.log(err)
            })
          }
        })

        this.$client.on('ChannelMessage', async ({ channelName, args }) => {
          const [message, memberId] = args
          // 开始投屏
          console.log('channel ', channelName, ', messsage: ', message.text, ', memberId: ', message.nickname)
          console.log(message.text.indexOf('conmand'))
          if (message.text == 'charge_0' || message.text == 'charge_1') {
            this.$emit('charge')
          } else if (message.text.indexOf('conmand') >= 0) {
            // 命令行
            // 走一遍showLive
            if (message.text == 'conmand:RTMInit') {
              console.log('成功接收到直播信息')
              this.$emit('reInitShow')
            }
          } else {
            const jsonMsg = JSON.parse(message.text)
            this.msgs.push({
              message: jsonMsg.text,
              nickname: jsonMsg.nickname,
              avatar: jsonMsg.avatar
            })
            this.$nextTick(() => {
              this.to_footer()
            })
          }
        })

        this.$client.on('MemberLeft', async ({ channelName, args }) => {
          const [memberId] = args
          console.log(args)
          // 主播离开了重新更新一遍
          const { user_id } = this.$route.query
          if (memberId == user_id) {
            // 主播进来了
            this.$emit('reInitShow')
          }
          get('/user/show', { user_id: memberId }).then(reo => {
            console.log(reo)
            const msg = '离开了直播间'
            this.msgs.push({
              message: msg,
              nickname: reo.data.data.user.nickname,
              avatar: reo.data.data.user.avatar
            })
            this.$nextTick(() => {
              this.to_footer()
            })
            const { id } = this.$route.params
            this.queryChannelUsers(id)
          })
        })
      }).catch((err) => {
        console.error(err)
      })
    },

    sendMsg(message) {
      this.$client.sendChannelMessage(message, this.channelName).then(() => {
        this.msgs.push({
          message: message,
          nickname: this.userInfo.nickname,
          avatar: this.userInfo.avatar
        })
        this.$nextTick(() => {
          this.to_footer()
        })
      }).catch((err) => {
        console.error(err)
      })
    },
    showFloatUser(user) {
      this.$emit('showFloatUser', user)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../common/style/variable.styl"
.live-message
  height 100%
  box-sizing border-box
  padding-bottom 112px
  position relative
  background #F4F7F
  .msg-scroll
    height 100%
    overflow-y hidden
    .tips
      line-height 48px
      margin-top: 40px
      padding 15px 30px 15px 30px
      font-size 30px
      color #FF8A43
    .msg-list
      height: calc(100vh - 820px);
      overflow-y: auto;

      &>li
        border-radius: 60px
        font-size: 56px
        display: inline-block
        max-width: 480px
        margin-bottom 30px
        padding 0 30px
        line-height 40px
        font-size 30px
        color #333333
        background: rgba(0,0,0,.4)
        padding: 10px 20px
        display: table
        .name
          color #B3B3B3
        &>img
          padding-right 14px
          display inline-block
          height 38px
          width: 38px;
          border-radius: 50%;
          overflow: hidden;
          vertical-align top
          &.medal
            margin-top -5px
            height 48px
  .input
    position absolute
    left 0
    bottom 0
    box-sizing border-box
    padding 0 30px
    width 100%
    height 112px
    display flex
    align-items center
    background #fff
    &>input
      flex 1
      height 78px
      padding-left 30px
      border-radius 39px
      background #F5F5F5
      font-size 30px
    &>button
      margin-left 20px
      width 150px
      height 74px
      border none
      outline none
      border-radius 37px
      background $theme-color
      font-size 28px
      color #FFF
  &.full-message
    background none
    padding-bottom 0
    .msg-scroll
      .tips
        background rgba(0, 0, 0, .2)
        color #FFEF37
        margin-bottom 26px
        border-radius 10px
      .msg-list
        &>li
          color #FFF
          .name
            color #5FFCFF
</style>
