import axios from 'axios'
import { Toast } from 'vant'
import { getItem, removeItem } from './storage'
import store from '../../store'
import qs from 'qs'

let loading = null

export function getSearch() {
  const query = window.location.search.substring(1)
  var vars = query.split('&')
  const res = {}
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    res[pair[0]] = pair[1]
  }
  return res
}

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://edu.pcmxj.com/api' : '/api', // https://edu.pcmxj.com '/api', // 'https://live.nasinet.com',
  timeout: 5000,
  crossDomain: true,
  withCredentials: false
})

instance.interceptors.request.use(
  (config) => {
    const token = getItem('token')
    if (config.headers['Content-Type'] == 'application/json;charset=UTF-8') {
      const c = {
        ...config,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          platform: 'H5'
        }
      }
      if (token) {
        c.headers.token = token
      }
      return c
    } else {
      const c = {
        platform: 'H5'
      }
      if (token) {
        c.token = token
      }
      return {
        ...config,
        headers: c
      }
    }
  }
)

instance.interceptors.response.use((res) => {
  loading && loading.close()
  if (res.data.code !== 0) {
    Toast.fail(res.data.msg)
    return Promise.reject(new Error(res.data.msg))
  }
  return res
}, (err) => {
  loading && loading.close()
  if (err.response.status === 401) {
    store.commit('changeUserInfo', {})
    removeItem('token')
    store.dispatch('initConfig')
    // return Promise.reject(err)
  }
  // Toast.fail('请求超时，请稍后再试')
  console.log(5555555555555555)
  return Promise.reject(err)
})

export const post = (url, data = {}) => {
  if (!data.hideLoading) {
    loading = Toast.loading()
  } else {
    delete data.hideLoading
  }
  const uid = getItem('uid')
  const token = getItem('token')
  if (!data) data = {}
  if (token && uid) {
    data.token = decodeURIComponent(token)
    data.uid = uid
  }
  return instance.post(url, data)
}

export const get = (url, data = {}) => {
  if (!data.hideLoading) {
    loading = Toast.loading()
  } else {
    delete data.hideLoading
  }
  const uid = getItem('uid')
  const token = getItem('token')
  if (!data) data = {}
  if (token && uid) {
    data.token = decodeURIComponent(token)
    data.uid = uid
  }
  return instance.get(url, {
    params: data,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  })
}
