/* eslint-disable no-undef */
import Vue from 'vue'
import Vuex from 'vuex'
import { post, get } from '../common/js/http'
import { getItem, setItem } from '../common/js/storage'
import router from '../router'
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)
// 判断是否是微信浏览器
function is_weixin() {
  const ua = navigator.userAgent.toLowerCase()
  return !!/micromessenger/.test(ua)
}

function getUrlParam(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

export default new Vuex.Store({
  state: {
    defaultAvatar: require('./moren@2x.png'),
    appConfig: {},
    categories: [],
    userInfo: {
      profile: {}
    },
    wxCode: '',
    tim: null,
    currentVideo: '',
    routerLoading: false,
    remoteUsers: 0
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo
    }
  },
  mutations: {
    queryChannelUsers(state, channel) {
      get('/live/show', { live_id: channel }).then((ret) => {
        state.remoteUsers = ret.data.data.live.watching_count
      })
    },
    setRemoteUsers(state, remoteUsers) {
      state.remoteUsers = remoteUsers.length
    },
    changeHistory(state, list) {
      state.routeHistory = list
    },
    changeConfig(state, data) {
      state.appConfig = data
    },
    changeCate(state, data) {
      state.categories = data
    },
    async wxShare(state, ret = {}) {
      return new Promise((resolve, reject) => {
        const url = window.location.href
        get('/common/jssdk', { url }).then(resp => {
          const { data: { data } } = resp
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: data.jsApiList // ['wx.updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
          })
          wx.ready(() => {
            wx.updateAppMessageShareData({ // 需在用户可能点击分享按钮前就先调用
              title: ret.title || '品创学社', // 分享标题
              desc: ret.desc ? ret.desc : '品创房地产教育致力于为房地产人才培养专业知识共享平台。该平台提供基础知识和实践经验分享等课程。基础入门课程包括CAD和规划设计、金融、ArcGIS、成本、市场营销、工业房地产、测绘、评估、办公软件技能提升以及当地房地产政策解读。高级实践课程包括规划和专项规划、申报、拆迁、税务规划和项目管理。向全国推广和传播珠江三角洲地区先进的城市更新和土地准备经验。知识体系由两部分组成：“线下+线上”。其中，‘线下’课程包括：企业内部培训、更新与服务学校、产品创新私人分享会、公开课、公开课等，品创更新准备学院和公开课为免费产品；企业内部培训、私人分享会和公益课都是付费产品。“品创房地产教育”课件的在线开发包括线下课程的录制和直播，与通过外部合作建立的教师团队共同传播房地产文化，以及重要政策推广和实施以及行业重要新闻发布会的直播。', // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: ret.cover || 'https://qnedue.pcmxj.com/public/logodddddddd.png', // 分享图标https://qnedue.pcmxj.com/public/logodddddddd.png
              success: function () {
              }
            })
            // 分享朋友圈
            wx.updateTimelineShareData({ // 需在用户可能点击分享按钮前就先调用
              title: ret.title || '品创学社', // 分享标题
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: ret.cover || 'https://qnedue.pcmxj.com/public/logodddddddd.png', // 分享图标
              success: function () {
              }
            })
            resolve(true)
          })
        })
      })
    },
    changeUserInfo(state, info) {
      state.userInfo = info
    },
    changeIm(state, im) {
      state.tim = im
    },
    changeVideo(state, video) {
      state.currentVideo = video
    },
    changeRouterLoading(state, loading) {
      state.routerLoading = loading
    },
    async loadUser(state, next) {
      const res = await get('/user/info', {})
      state.userInfo = res.data.data.user
      next()
    },
    async setWxCode(state, code) {
      let token = getItem('token')
      if (!token) {
        token = getUrlParam('token')

        post('/common/tokenConvert', {
          token
        })
        .then(({ data: { data: { token } } }) => {
            setItem('token', token)            
        })

      }
      if (is_weixin() && window.innerWidth < 768 && !token) {
        const code = getUrlParam('code') // 截取路径中的code.
        // const state1 = getUrlParam('state')
        if (code == null || code === '') {
          const url = window.location.href
          window.location.href =
            'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3a1de8363f4410a4&redirect_uri=' +
            url +
            '&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect'
        }
        // if (code !== '' && code != null && state1 == 1) {
        //   state.wxCode = code
        //   await this.commit('getOpenid')
        // }
      }
    },
    async getOpenid(state, next) {
      const token = getItem('token')
      if (!token) {
        const res = await post('/passport/authorize', {
          code: state.wxCode
        })
        setItem('token', res.data.data.token)
        await this.commit('loadUser', next)
      }
      
    }
  },
  actions: {
    async initConfig({ commit }) {
      await commit('setWxCode')
      // commit('getOpenid')
      commit('changeCate', [
        {
          id: 1,
          title: '系统课',
          icon: 'https://meet1v1-1300631461.cos.ap-shanghai.myqcloud.com/images/2020020114512325325IMG_7552.JPG',
          sort: 1,
          status: 1
        },
        {
          id: 2,
          title: '直播课',
          icon: 'https://meet1v1-1300631461.cos.ap-shanghai.myqcloud.com/images/2020020114512325325IMG_7552.JPG',
          sort: 2,
          status: 1
        },
        {
          id: 3,
          title: '视频课',
          icon: 'https://meet1v1-1300631461.cos.ap-shanghai.myqcloud.com/images/2020020114512325325IMG_7552.JPG',
          sort: 3,
          status: 1
        },
        {
          id: 9,
          title: '讲师',
          icon: 'https://meet1v1-1300631461.cos.ap-shanghai.myqcloud.com/images/2020020114512325325IMG_7552.JPG',
          sort: 4,
          status: 1
        },
        {
          id: 5,
          title: '机构',
          icon: 'https://meet1v1-1300631461.cos.ap-shanghai.myqcloud.com/images/2020020114512325325IMG_7552.JPG',
          sort: 5,
          status: 1
        }
      ])
    },
    getUserInfo({ commit }) {
      const token = getItem('token')
      if (!token) return
      try {
        get('/user/info', {}).then(
          ({
            data: {
              data: { user }
            }
          }) => {
            commit('changeUserInfo', user)
          }
        )
      } catch (error) {

      }
    }
  },
  plugins: [createPersistedState()],
  modules: {}
})
