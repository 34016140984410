<template>
  <div class="live">
    <div class="top-center">
      <div>
        <van-search
          readonly
          @click="$router.push('/search')"
          placeholder="搜索课程、讲师、视频"
        />
      </div>
      <div class="tab">
        <van-tabs @click="changeTab" v-model="active" scrollspy color="#FF6600" :line-width="13" animated>
          <van-tab v-for="(item, index) in tabs" :key="item.id" :name="index" :title="item.title"></van-tab>
        </van-tabs>
        <img @click="$router.push('/search')" src="./ic_search@2x.png" alt="" class="search" />
      </div>
    </div>
    <list ref="liveList"  :type="type"  class="liveList-container"/>
    <!-- <tab-bar/> -->
  </div>
</template>

<script>
// import TabBar from '../../components/tab-bar/tab-bar'
import List from './list'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'live',
  components: {
    // TabBar,
    List
  },
  data() {
    return {
      type: 1,
      active: 0,
      initAc: 0,
      adList: []
    }
  },
  computed: {    
    ...mapState(['categories']),
    tabs() {
      return [...this.categories]
    }
  },
  activated() {
    // this.initAc = this.active
    // if (this.$refs.liveList) {
    //   this.$refs.liveList.forEach(item => {
    //     item.activated()
    //   })
    // }
  },
  async created() {    
    const { isLive  } = this.$route.query 
    await this.initConfig()         
    this.getAd()
    if (isLive == 1) {
      this.active = Number(isLive)   
      this.type = this.active + 1 
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.createSwiper()
    // }, 500)
  },
  methods: {
    ...mapActions(['initConfig']),
    changeTab(index) {      
      // this.swiper.slideTo(index)
      this.type = index + 1
      this.$refs.liveList.reset()      
      this.$refs.liveList.getList(false, this.type)
    },
    getAd() {
      // get('/live/list', {
      //   page: 1
      // })
      //   .then(({ data: { data } }) => {
      //     this.adList = data
      //   })
    }
  }
}
</script>

<style scoped lang="stylus">
.liveList-container
  height: calc(100vh - 248px)
.live
  height 100%
  /*::v-deep*/
  /*  .van-tabs__wrap--scrollable .van-tabs__nav--complete*/
  /*    padding-right 63px*/
  .tab
    width 92%
    position: relative
  .search
    position: absolute
    width 26px
    height 28px
    right -30px
    top 28px
  .live-swipe
    width 100vw
    height calc(100% - 98px - 1.17333rem)
    z-index 1 !important
    background-color: #F5F6FA
</style>
