<!-- eslint-disable no-undef -->
<!-- eslint-disable eqeqeq -->
<!-- eslint-disable no-unused-vars -->
<template>
  <div v-if="live" class="live-detail" @click="toTouch">
    <div
      class="player-wrap"
      :class="{
        'full-player': live.orientation == 2,
        'dplayer-hide-controller': isPlaying
      }"
      id="player_wrap"
    >
        <template >
            <div class="no-start" v-if="!isStart">
              等待主播就位
            </div>
            
            <agora  @user-left="userLeft"  @user-published="handleUserPublished"  @autoplay-failed="handleAutoplayFailed" ref="ar" @client-created="handleClientCreated" :token="options.token" @join-success="handleJoinSuccess" @user-joined="handleUserJoin"  :channel="options.channel" :autoStart="true" :uid="options.uid" appid="06ad5e9fc17a41a99f4a97224220ea27" :clientConfig="{ mode: 'live', role: 'audience' }">
                <agora-video-receiver  customizationPlayer @video-ready="handleVideoReady" ref="receiver" :playerConfig="{mirror: false}">
                        <div  class="loop-player"  ></div>
                </agora-video-receiver>
                <agora-audio-receiver  :volume="volume"/>
            </agora>

           <div  class="loop-player" :class="media ? media.classes: ''" v-for="(  media, key) in videoList" :key="key" v-player="media">
            <div  @click="redZheDie" style="width:20px;height:20px;" class="zhedie-btn point"  v-if="media.classes == 'video-viewer' && !istouch">
              <img :src="require('@/assets/img/zhedie.png')" style="width:50%;height:50%;"/>
            </div>

            <div @click="redHebing" class="open-ball" :style="`background-image:url(${require('@/assets/img/zhankai.png')})`" v-if="media.classes == 'video-viewer'">
            </div>
           </div>

           <div @click="toTouch" class="view flex-row align-center full-player-btn p-t-20 p-l-20 p-r-20 p-b-20 justify-center" v-if="istouch && live.status == 1" >
              <img :src="require('@/assets/img/bf.png')" class="m-r-20" style="width:15px;height: 15px;"/>
              <span>观看直播</span>
           </div>

          <div class="view flex-row align-center justify-between m-t-24 m-r-40" v-if="showBar" style="position: relative;">
            <div class="view flex-row align-center m-l-30">
              <img src="./no-voice.png" style="width:12px;height:12px;" class="m-r-40" v-if="volume == 0" @click="rebackVol"/>
              <img src="./voice.png" style="width:12px;height:12px;" class="m-r-40" v-else @click="setVolume"/>
              <van-slider v-model="volume" bar-height="4px" active-color="#FF5500" class="slid-war">
                <template #button>
                  <div class="custom-button">{{ volume }}</div>
                </template>
              </van-slider>
            </div>

            <div
              v-if="isAn"
              style="width:16px;height:16px;
              position: absolute;
              right: 0px;
              z-index: 99;
              top: 0;" @click="FullScreen">
              <img src="./hp.png" style="width:100%;height: 100%;" />
            </div>

          </div>
        </template>

    </div>
    <template >
      <div class="anchor">
        <img class="avatar" :src="anchor.avatar || defaultAvatar" />
        <div mode="aspectFit" class="info">
          <p class="ellipse-one">{{ anchor.nick_name }}</p>
          <span class="ellipse-one">热度：{{parseInt(Math.random()*200) + 300}}</span>
        </div>
        <img
          class="focus"
          @click="focus"
          v-if="!live.is_follow"
          src="./wechat/button_guanzhu@2x.png"
          alt
        />
        <img
          class="focus"
          @click="focus"
          v-else
          src="./wechat/button_yiguanzhu@2x.png"
          alt=""
        />
      </div>
      <div class="honey-rank">
        <div class="scroll">
          <div
            @click.stop="changeFloatUser(item.user.user_id)"
            :class="'avatar ' + (index < 3 ? 'bg' + index : '')"
            v-for="(item, index) in honeyRank"
            :key="item.id"
          >
            <img :src="item.user.avatar || defaultAvatar" alt="" />
          </div>
        </div>
        <p class="num">{{ remoteUsers }}</p>
      </div>
      <!-- <div class="rank-btn-group">
        <div class="rank-btn" @click.stop="showMoneyRank = true">
          <img src="./wechat/ic_list@2x.png" alt="" />
          <p>贡献榜</p>
        </div>
        <div class="rank-btn">
          <img src="./wechat/ic_shouhu@2x.png" alt="" />
          <p>守护33人</p>
        </div>
        <div class="rank-btn" v-if="isMgr" @click.stop="showSetList = true">
          <img src="./wechat/ic_Banned@2x.png" alt="" />
          <p>禁言列表</p>
        </div>
      </div> -->
      <div class="message-control">
        <message
          ref="message"
          @charge="charge"
          @isLogined="isLogined"
          is-full-screen
        />
      </div>
      <div class="bottom">
        <div class="input" @click="handleSlowInput">
          <img src="./wechat/ic_msg@2x.png" alt="" />
          <span>说点什么...</span>
        </div>
        <!-- <img src="./wechat/gouwuche@2x.png" alt="" /> -->
        <!-- <img src="./wechat/lianmai@2x.png" alt="" /> -->
        <img
          @click.stop="alertType = 'gift'"
          src="./wechat/ic_gift@2x.png"
          alt=""
        />
        <!-- <img src="./wechat/ic_message@2x.png" alt="" />
        <img src="./wechat/share@2x.png" alt="" /> -->
        <img @click="toHome" src="./wechat/close@2x.png" alt="" />
      </div>
      <form v-if="showInput" @submit.prevent="submitMsg()" class="form-control">
        <input
          ref="megInput"
          v-model="message"
          placeholder="快来调侃一下主播吧"
          type="text"
        />
        <button type="submit" class="btn">发送</button>
      </form>
      <gift-send
        :detail="detail"
        v-if="alertType === 'gift'"
        @sendGift="sendGift"
        @close="alertType = ''"
      />
      <user-float
        @set="mutedUser"
        v-if="alertType === 'userFloat'"
        @close="alertType = ''"
        :show-set="showSet"
        :uid="currentUserId"
      />
      <van-popup
        v-model="showMoneyRank"
        round
        position="bottom"
        :style="{ height: '60vh' }"
      >
        <money-rank background="none" v-if="anchor.id" :anchorid="anchor.id" />
        <div class="safe-area-size-box"></div>
      </van-popup>

      <van-popup
        v-if="isMgr"
        v-model="showSetList"
        round
        position="bottom"
        :style="{ height: '60vh' }"
      >
        <set-list
          ref="setList"
          :is-open="showSetList"
          :show-mgr="false"
          v-if="anchor.id"
          :anchorid="anchor.id"
        />
        <div class="safe-area-size-box"></div>
      </van-popup>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { post, get } from '../../common/js/http'
import Message from '../../components/message/message'
export default {
  name: 'live-detail',
  components: {
    Message,
    GiftSend: () => import('../../components/gift-send/gift-send'),
    SetList: () => import('../../components/set-list/set-list'),
    UserFloat: () =>
      import('../../components/message-user-float/message-user-float'),
    MoneyRank: () => import('./money-rank'),
    AnchorInfo: () => import('./anchor-info')
  },
  data() {
    return {
      options: {},
      isAn: false,
      videoList: [],
      youAreShareScreening: false,
      live: null,
      IMToken: '',
      honeyRank: [],
      showBar: false,
      humanNum: '0',
      isMgr: false,
      message: '',
      showInput: false,
      showSetList: false,
      alertType: '',
      detail: {},
      isLoading: false,
      currentUserId: 0,
      isPlaying: false,
      showMoneyRank: false,
      istouch: false,
      isStart: false,
      anchor: {
        isattent: false,
        profile: {}
      },
      tabs: [
        { id: 1, title: '聊天' },
        { id: 2, title: '主播' },
        { id: 3, title: '贡献榜' }
      ],
      active: 0,
      volume: 50
    }
  },

  computed: {
    ...mapState(['defaultAvatar', 'userInfo', 'remoteUsers']),
    showSet() {
      return (
        this.isMgr &&
        this.currentUserId !== this.userInfo.user_id &&
        this.currentUserId !== this.anchor.id
      )
    }
  },
  created() {
    this.getDetail()
    this.checkAn()
  },
  mounted() {
  },
  beforeDestroy() {
    this.$refs.ar.leave()
  },
  methods: {
    ...mapMutations(['setRemoteUsers', 'wxShare']),
    checkAn() {
      var u = navigator.userAgent; var app = navigator.appVersion
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
      if (isAndroid) {
        this.isAn = true
      } else {
        this.isAn = false
      }
    },
    is_weixin() {
      const ua = navigator.userAgent.toLowerCase()
      return !!/micromessenger/.test(ua)
    },
    handleClientCreated() {
        // window.onresize = () => {
        //   if (window.innerWidth > 400) {
        //     //横屏

        //   } else {

        //   }
        // }
    },
    charge() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    setVolume() {
      this.rebackvolume = this.volume
      this.volume = 0
    },
    rebackVol() {
      this.volume = this.rebackvolume
    },
    sendGift (gift) {
      this.$refs.message.sendMsg(`给主播送出了 <img src="${gift.icon}" style="width:17px;height:17px;"/>`)
    },
    toTouch() {
      this.istouch = false
    },
    handleAutoplayFailed() {
      this.istouch = true
    },

    // 进入全屏
    FullScreen() {
      const ele = $('.rtc-room')[0]
      if (ele.requestFullscreen) {
        ele.requestFullscreen()
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen()
      } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen()
      }
    },
    // 退出全屏
    exitFullscreen() {
      const de = $('.rtc-room')[0]

      if (de.exitFullscreen) {
        de.exitFullscreen()
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen()
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen()
      }
    },
    handleUserJoin(user) {
      // const remoteUsers = this.$refs.ar.getRemoteUsers()
      // console.log(remoteUsers)
      // this.setRemoteUsers(remoteUsers)

      if (this.live.user.user_id === user.uid) {
        // 主播进入房间了
        this.$emit('anchorJoin')
      }
    },
    handleVideoReady(data) {
      if (data.length == 2) {
        const { user_id } = this.$route.query
        this.youAreShareScreening = true
        const da = data.map(item => {
          if ((item.uid / 10000) == user_id) {
            item.classes = 'rtc-room'
          } else {
            item.classes = 'video-viewer'
          }
          return item
        })
        this.videoList = da
      } else {
        if (data.length > 0) {
          data[0].classes = 'rtc-room'
        }
        this.youAreShareScreening = false
        this.videoList = data
      }
    },
    redZheDie() {
      $('.zhedie-btn').hide()
      $('.open-ball').show()
      $('.video-viewer').addClass('share-toggle-hide')
    },
    redHebing() {
      $('.zhedie-btn').show()
      $('.open-ball').hide()
      $('.video-viewer').removeClass('share-toggle-hide')
    },
    handleUserPublished() {
      this.isStart = true
      this.showBar = true
    },
    async handleJoinSuccess(uid) {
      this.inMeeting = true
      this.uid = uid

      setTimeout(() => {
        this.showBar = true
      }, 1000)
    },
    userLeft(user) {
      if (this.live.user.user_id == user.uid) {
        this.isStart = false
        this.showBar = false
      }
    },
    changHumanNum(num) {
      this.humanNum = num
    },
    changeFloatUser(id) {
      this.currentUserId = id
      this.alertType = 'userFloat'
    },

    mutedUser(user) {
      this.$confirm({
        title: '请选择您要做的操作',
        message: `用户：${user.nick_name}（ID：${user.id}）`,
        confirmButtonText: '禁言'
      }).then(() => {
        // post('/webapi/live/banUser', {
        //   userid: user.id,
        //   type: 1,
        //   anchorid: this.anchor.id
        // }).then(() => {
        //   this.alertType = ''
        // })
      })
    },
    handleSlowInput() {
      if (!this.userInfo.user_id) {
        this.$toast('请使用微信网页登录')
        return
      }
      this.showInput = true
      this.$nextTick(() => {
        this.$refs.megInput.focus()
      })
    },
    focus() {
      const { user_id } = this.$route.query
      post('/lecturer/follow', {
        lecturer_id: user_id
      }).then(({ data: { data } }) => {
        const { id } = this.$route.params
        get('/live/show', {
          live_id: id
        }).then(({ data: { data } }) => {
          this.live = data.live
          if (this.live.is_follow) {
            this.$toast('已关注~')
          } else {
            this.$toast('已取消关注~')
          }
        })
      })
    },
    submitMsg() {
      if (this.message === '') {
        this.$toast.fail('请输入消息')
        return
      }
      this.msgTimer && clearTimeout(this.msgTimer)
      this.msgTimer = setTimeout(() => {
        this.$refs.megInput.blur()
        this.$refs.message.sendMsg(this.message)

        this.message = ''
        this.showInput = false
      }, 200)
    },

    createSwiper() {
      this.swiper = new window.Swiper('.detail-swiper', {
        loop: false,
        threshold: 10,
        on: {
          slideChangeTransitionStart: () => {
            this.active = this.swiper.activeIndex
          }
        }
      })
    },

    changeTab(index) {
      this.swiper.slideTo(index)
    },

    getDetail() {
      const { id } = this.$route.params
      console.log('showLive')
      get('/live/show', {
        live_id: id
      }).then(({ data: { data } }) => {
        this.live = data.live
        const shareData = {
          cover: data.share.cover,
          title: this.live.title,
          user: this.live.user,
          desc: `讲师：${this.live.user.nickname}\n正在直播中`
        }
        this.wxShare(shareData)
        this.$nextTick(() => {
          this.createPlayer()
        })
      })
    },
    toHome() {
      this.$router.replace('/')
    },
    async createPlayer() {
      const { id } = this.$route.params

      const res = await get('/user/rtcToken', {
        is_audience: this.userInfo.user_id == this.live.user.user_id ? 1 : 0,
        live_id: id
        // 开启共享获取一个token
        // is_share: this.userInfo.user_id == this.live.user.user_id
      })
      this.options = {
        token: res.data.data.rtc_token,
        channel: id,
        uid: this.userInfo.user_id == this.live.user.user_id ? Number(this.userInfo.user_id) * 1000 : this.userInfo.user_id
      }
      if (this.live.status === 1) {
        console.log('初始化video=====================>')
        this.isStart = true
      } else if (this.live.status === 0) {
        this.isStart = false
      }
    },
    isLogined() {
      const { id } = this.$route.params
      this.$refs.message.joinChannel(id)
    }
  }
}
</script>

<style scoped lang="stylus">
@import '../../common/style/variable.styl';
 

.zhedie-btn {
  width: 20px;
  height: 20px;
  background-color: rgba(255,255,255,1);
  position: absolute;
  top: 0;
  z-index: 80;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  transition: all ease .5s;
}
.zhedie-btn:hover {
  transform: scale(1.1);
}
.open-ball {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  bottom: 80px;
  left: 45px;
  z-index: 25;
  cursor: pointer;
  display: none;
}
.safe-area-size-box {
  height calc(constant(safe-area-inset-bottom) + 20px)
  height calc(env(safe-area-inset-bottom) + 20px)
}
/deep/ .full-player .dplayer-controller,
/deep/ .full-player .dplayer-controller-mask{
  display: none;
}
.custom-button
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  background-color: rgba(255,85,0,.8);
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
.slid-war
  width: 200px;
  margin-right: 60px;
  justify-content: center;
  align-items: center;
.loop-player
  height: 490px;
.live-detail {
  height: 100%;
  overflow: hidden;
  background: rgba(0,0,0,.9);
  .player-wrap {
    background: transparent;
    position: relative;
    top: 0px;
    &.full-player {
      height: 100vh;

      /deep/ .dplayer-video {
        object-fit: cover;
      }
    }
  }

  .video-viewer {
      position: absolute;
      width: 200px !important;
      height: 200px !important;
      z-index: 300;
      bottom: 50px;
      transition: all ease .5s;
      &.share-toggle-hide {
        width : 0px !important;
        height : 0px !important;
      }
  }

  .head {
    display: flex;
    justify-content: space-between;
    background: #fff;

    .tab-wrap {
      width: 359px;
    }

    .focus {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 146px;
      height: 1.17333rem;
      line-height: 1.17333rem;
      background: $theme-color;

      &>img {
        width: 30px;
        height: 26px;
        padding-right: 11px;
      }

      &>span {
        font-size: 26px;
        color: #FFFFFF;
      }
    }
  }

  .detail-swiper {
    width: 100%;
    height: calc(100% - 490px - 1.17333rem);
  }
}
.form-control {
  position absolute
  bottom 0
  left 0
  right 0
  z-index 20
  background #ffffff
  display flex
  height calc(constant(safe-area-inset-bottom) + 90px)
  height calc(env(safe-area-inset-bottom) + 90px)
  input {
    box-sizing border-box
    flex 1
    height 90px
    padding 15px 20px
    line-height 60px
    background none
    border 0 none
    display block
    color #333
    font-size 26px
    &::placeholder {
      color rgba(0, 0, 0, .6)
    }
  }
  .btn {
    height 90px
    line-height 90px
    width 150px
    text-align center
    color #ffffff
    font-size 30px
    background #FF6273
    border 0 none
    outline 0
  }
}

.message-control {
  position: absolute;
  z-index 9
  left: 30px;
  right: 30px;
  height: calc(100vh - 680px);
  overflow: hidden;
  bottom calc(constant(safe-area-inset-bottom) + 100px)
  bottom calc(env(safe-area-inset-bottom) + 100px)
}

.bottom
  position absolute
  bottom calc(constant(safe-area-inset-bottom) + 20px)
  bottom calc(env(safe-area-inset-bottom) + 20px)
  left 0
  width 100%
  box-sizing border-box
  padding 0 30px
  display flex
  justify-content space-between
  z-index 9
  .input
    flex 1
    height 65px
    border-radius 33px
    padding 0 20px
    display flex
    justify-content flex-start
    align-items center
    background rgba(0,0,0,0.2)
    overflow hidden
    &>img
      flex-shrink 0
      margin-right 10px
      width 39px
      height 39px
    &>span
      font-size 25px
      color #FFFFFF
  &>img
    flex-shrink 0
    width 63px
    height 63px
    margin-left 20px
    border-radius 50%
.rank-btn-group {
  position absolute
  z-index 9
  left 30px
  top 117px
  max-width 340px
  display flex
  flex-wrap wrap
  align-items flex-start
}
.rank-btn
  flex-shrink  0
  padding 0 11px
  height 41px
  margin-bottom 11px
  border-radius 22px
  display flex
  align-items center
  justify-content center
  background-color rgba(0,0,0,0.2)
  margin-right 12px
  &>img
    width 31px
    height 31px
  &>p
    margin-left 7px
    font-size 22px
    font-weight 500
    color #FFFFFF

.honey-rank
  position absolute
  top 30px
  right 30px
  display flex
  align-items center
  z-index 9
  .num
    margin-top 7px
    margin-left 9px
    padding 0 12px
    height 36px
    line-height 36px
    border-radius 18px
    background rgba(0, 0, 0, 0.8)
    font-size 20px
    color #FFFFFF
  .scroll
    width 256px
    height 67px
    display flex
    align-items flex-start
    overflow-x auto
    .avatar
      width 53px
      height 53px
      min-width 53px
      min-height 53px
      margin-left 7px
      margin-top 10px
      &.bg0
        margin-left 0
        margin-top 0
        width 64px
        height 67px
        min-width 64px
        min-height 67px
        background url(./wechat/no1@2x.png) center center no-repeat
        background-size contain
        position relative
        &>img
          width 53px
          height 53px
          position absolute
          bottom 2px
          right 2px
      &.bg1
        margin-left 0
        margin-top 0
        width 64px
        height 67px
        min-width 64px
        min-height 67px
        background url(./wechat/no2@2x.png) center center no-repeat
        background-size contain
        position relative
        &>img
          width 53px
          height 53px
          position absolute
          bottom 2px
          right 2px
      &.bg2
        margin-left 0
        margin-top 0
        width 64px
        height 67px
        min-width 64px
        min-height 67px
        background url(./wechat/no2@2x.png) center center no-repeat
        background-size contain
        position relative
        &>img
          width 53px
          height 53px
          position absolute
          bottom 2px
          right 2px
      &>img
        width 56px
        height 56px
        border-radius 50%
.anchor {
  position: absolute;
  z-index: 9;
  left: 30px;
  top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
  box-sizing: border-box;
  min-width: 300px;
  height: 72px;
  border-radius: 36px;
  background: rgba(0, 0, 0, 0.2);

  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  .info {
    margin-left: 11px;
    margin-right: 16px;
    flex: 1;
    line-height: 24px;
    color: #FFFFFF;

    &>p {
      margin-bottom: 7px;
      width: 98%;
      font-size: 24px;
    }

    &>span {
      display: block;
      width: 98%;
      font-size: 20px;
      color: #FFFFFF;
    }
  }

  .focus {
    width: 55px;
    height: 55px;
  }
}
.no-start
  height: 522px
  width 100%
  background: rgba(0,0,0,.6)
  display: flex
  justify-content: center
  align-items: center
  color #fff
.full-player-btn
  border-radius: 10px
  background-color: #fff
  width: 200px
  font-weight: bold
  position: absolute
  left: 0
  right: 0
  top: 0
  bottom: 0
  margin: auto
  height: 30px

</style>
