<!--
 * @Author: guanqifeng 493683987@qq.com
 * @Date: 2023-01-12 14:05:11
 * @LastEditors: guanqifeng 493683987@qq.com
 * @LastEditTime: 2023-02-17 14:00:58
 * @FilePath: \live-video-mobile\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <div @touchmove.prevent v-if="routerLoading" class="router-loading">
      <van-loading size="50px" type="circular" color="#ffa519" />
    </div>
    <keep-alive include="home,live,group">
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getItem, setItem } from './common/js/storage'
import { post } from './common/js/http'
import moment from 'moment'

export default {
  async created() {
    // window.localStorage.setItem('token', '')
    // await this.initConfig()  
    // this.createTourist()
  },
  computed: {
    ...mapState(['routerLoading'])
  },
  methods: {
    ...mapActions(['initConfig', 'getUserInfo'])
  }
}
</script>

<style lang="stylus">
  html
    height 100%
    min-height 100%
  body
    height 100%
  #app
    height 100%
  .router-loading
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    background #fff
    z-index 99999
    display flex
    justify-content center
    align-items center
</style>
