<template>
  <van-col :span="type != 4 ? 12: 24">
    <div class="live-item p-b-15 m-b-20" @click.stop="goDetail(live.id, live.user.user_id)" v-if="type != 4">
      <div>
        <div class="list-item-Image m-b-20" :style="{backgroundImage: `url(${live.cover})`}"></div>

        <div class="p-l-20 p-r-20">
          <div class="fz-26 l-h-15 m-b-15 line-title">{{ live.title }}</div>
          <div class="view flex-row align-center m-b-20">
            <div class="p-l-15 p-r-15 p-t-10 p-b-10 tag" v-for="(item,index) in live.tags" :key="index">{{ item }}</div>
          </div>

          <div class="view flex-row align-center justify-between ">
              <div class="view flex-row align-center">
                <div class="view flex-row align-center">
                  <div class="avaruar">
                    <img :src="live.user.avatar" class="width100 height100" />
                  </div>
                  <div class="fz-12 c_7d">{{ live.user.nickname }}</div>
                </div>
              </div>
              <div class="c_7d fz-12">{{ dayjs(live.begin_time).format('MM-DD')  }} 开播</div>
          </div>

          <div class="view flex-row align-center m-t-30" v-if="type == 1">
              <div class="view flex-row align-center m-r-12 fz-26 c_ff5">
                免费
              </div>
              <div class="c1 fz-26">{{ Math.floor(Math.random()*100+1) }}人看过</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="box-item">
        <div class="p-l-30 p-r-30 view align-center flex-row">
          <div class="put-avaruar-img">
            <img  :src="live.avatar"/>
          </div>
          <div class="view flex-1">
            <div class="m-b-30 view align-center justify-between flex-row">
                <div class="fz-32 c_333 bold">{{ live.nickname }}</div>
                <div class="c_008 bold">+关注</div>
            </div>
            <div class="c_777 fz-28 l-h-15" v-html="live.desc || '他很神秘，暂无公开描述哦~'">
            </div>
          </div>
        </div>
    </div>
  </van-col>

</template>

<script>
import { anchorLevel } from '../../common/js/level-icon'
import dayjs from 'dayjs'

export default {
  name: 'live-item',
  props: {
    type: {
      type: Number,
      default() {
        return 1
      }
    },
    live: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      dayjs,
      anchorLevel,
      roomType: ['普通房间', '私密房间', '付费房间'],
      typeClass: ['orange', 'blue', 'purple']
    }
  },
  computed: {
    // hot() {
    //   if (this.live.hot >= 10000) {
    //     return (this.live.hot / 10000).toFixed(1) + 'w'
    //   }
    //   return this.live.hot
    // }
  },
  methods: {
    goDetail(live_id, room_user_id) {
      // if (this.live.room_type === 2) {
      //   this.$confirm({
      //     title: '提示',
      //     message: '当前房间为付费房间，请前往APP观看'
      //   })
      //     .then(() => {
      //       this.$download()
      //     })
      //   return
      // }
      // if (this.live.room_type !== 0) {
      //   const confirm = this.$buyConfirm({
      //     props: {
      //       type: 'password'
      //     },
      //     methods: {
      //       confirm: (password) => {
      //         if (CryptoJS.MD5(password).toString() === this.live.password) {
      //           this.$router.push('/live/' + this.live.anchorid)
      //           confirm.hide()
      //         } else {
      //           this.$toast.fail('密码错误')
      //         }
      //       }
      //     }
      //   })
      //   return
      // }
      if (this.type === 1) {
        this.$router.push({
          path: `/setup/${live_id}`,
          query: {
            user_id: room_user_id
          }
        })
      } else if (this.type === 2) {
        this.$router.push({
          path: '/live/' + live_id,
          query: {
            user_id: room_user_id
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.list-item-Image {
  width: 100%;
  height: 190px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.box-item {
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom:30px;
}
.avaruar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right:10px;
}
.put-avaruar-img {
  width: 124px;
  height: 124px;
  background-color: #008C8C;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  img {
    width: 116px;
    height: 116px;
    border-radius: 50%;
  }
}
.live-item {background-color: #fff;border-radius: 10px;padding-bottom: 30px;margin-bottom: 20px;box-shadow: 0 0 3px rgba(0,0,0,.3);}
.tag {background-color: #F0F1F5;border-radius: 3px;margin-right: 5px;color: #999999;}
.line-title {
  height: 80px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5;
}
</style>
