<template>
  <scroll ref="scroll" clickable :is-finished="isFinished" @scrollToEnd="scrollToEnd">
    <div @touchmove.stop v-if="type===0">
      <div :class="['swiper-container' + type, 'loop']">
        <div class="swiper-wrapper">
          <a :href="item.jump_url" class="swiper-slide" :style="{backgroundImage: `url(${item.image_url})`}" v-for="item in adList" :key="item.id">
          </a>
        </div>
      </div>
    </div>

    <div class="space" v-else></div>
    <div>
      <van-row gutter="10" class="p-l-28 p-r-28" v-if="list.length > 0">
        <live-item class="live-link" v-for="(live, index) in list" :key="index" :live="live" :type="type"/>
      </van-row>
      <van-empty description="暂无视频" v-else/>
    </div>
  </scroll>
</template>

<script>
import Scroll from '../../components/scroll/scroll'
import LiveItem from '../../components/live-item/live-item'
import { get, post } from '../../common/js/http'

export default {
  name: 'list',
  components: {
    Scroll,
    LiveItem
  },
  props: {
    type: {
      type: Number,
      default: 1
    },
    index: {
      type: Number,
      default: 0
    },
    active: {
      type: Number,
      default: 0
    },
    adList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      list: [],
      isInit: false,
      page: 1,
      isFinished: true
    }
  },
  created() {
    if (this.active === this.index && !this.list.length && !this.isInit) {
      this.isInit = true
      const { isLive  } = this.$route.query      
      if (isLive) {
        this.getList(false, Number(isLive)+1)
      }  else {
        this.getList()
      }
      
    }
  },
  mounted() {
    // if (this.adList.length) {
    // }
    // this.$nextTick(() => {
    //   this.createSwiper()
    // })
  },
  methods: {
    // createSwiper() {
    //   this.swiper = new window.Swiper('.swiper-container' + this.type, {
    //     slidesPerView: 'auto',
    //     centeredSlides: true,
    //     loop: true,
    //     spaceBetween: 10,
    //     grabCursor: true,
    //     preventClicksPropagation: false
    //   })
    // },
    reset() {
      this.page = 1
    },
    getList(hideLoading = false, type = 1) {
      if (this.page === 1) {
        this.list = []
      }
      if (type === 1) {
        post('/course/list', {
          page: this.page,
          hideLoading
        })
          .then(({ data: { data: { list } } }) => {
            this.list = this.list.concat(list)
            this.isFinished = list.length < 12
            this.$nextTick(() => {
              this.$refs.scroll.refresh()
              this.loading = false
            })
          })
      } else if (type === 2) {
        get('/live/list', {
          page: this.page,
          hideLoading
        })
          .then(({ data: { data: { list } } }) => {
            this.list = this.list.concat(list)
            this.isFinished = list.length < 12
            this.$nextTick(() => {
              this.$refs.scroll.refresh()
              this.loading = false
            })
          })
      } else if (type === 3) {
        get('/lesson/list', {
          page: this.page,
          hideLoading
        })
          .then(({ data: { data: { list } } }) => {
            this.list = this.list.concat(list)
            this.isFinished = list.length < 12
            this.$nextTick(() => {
              this.$refs.scroll.refresh()
              this.loading = false
            })
          })
      } else if (type === 4) {
        get('/lecturer/list', {
          page: this.page,
          hideLoading
        })
          .then(({ data: { data: { list } } }) => {
            this.list = this.list.concat(list)
            this.isFinished = list.length < 12
            this.$nextTick(() => {
              this.$refs.scroll.refresh()
              this.loading = false
            })
          })
      }
    },

    scrollToEnd() {
      if (this.loading) return
      this.loading = true
      this.page++
      this.getList(true)
    },
    activated() {
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    }
  },
  watch: {
    active(newVal) {
      if (newVal === this.index && !this.list.length && !this.isInit) {
        this.isInit = true
        this.getList()
      }
    },
    adList(newVal) {
      if (newVal.length) {
        this.$nextTick(() => {
          this.createSwiper()
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.list
  display flex
  flex-wrap wrap
  padding 0 9px
  @media only screen and (min-width: 768px)
    padding 0
    justify-content flex-start
    .live-link
      margin-left 1%
      width 32%
      border: 1px solid #F0F1F5
  @media only screen and (max-width: 767px)
    justify-content space-between
.space
  height 23px
.loop
  width 100%
  height 173px
  margin-top 33px
  margin-bottom 23px
  ::v-deep
    .swiper-slide
      width 609px
      display block
      background-repeat no-repeat
      background-size cover
      background-position center center
      // transform scale(0.83)
      transition all 0.3s
      border-radius 20px
      overflow hidden
    .swiper-slide-active
      transform scale(1)
</style>
